import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from '../firebase';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SignUpSignIn.css";
import Loader from "./Loader";

const LoginForm = ({ onForgotPassword }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter an email");
      return;
    }

    if (!password) {
      toast.error("Please enter the password");
      return;
    }

    const emailRegex = /^[^@\s]+@huntsmanoptics\.com$/;

    if (!emailRegex.test(email)) {
      toast.error("Your email syntex should be '<Name>@huntsmanoptics.com'");
      setEmail('');
      setPassword('');
      return;
    }

    try {
      setIsLoading(true);
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        await auth.signOut();
        toast.error('Please verify your email address before logging in.');
        setIsLoading(false);
        return;
      }

      toast.success('Login successful!');
      console.log("login done")
      navigate('/home', { replace: true });
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Email does not exist');
      } else {
        toast.error("Error during login");
      }
      setEmail('');
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="form-containerA sign-in-containerA">
      {isLoading && 
        <div className="loader2-container">
          <Loader />
        </div>
      }
      <form onSubmit={handleLogin} className="form1">
        <h2 style={{ fontWeight: "bold", marginBottom: "15px" }}>Sign in</h2>
        <input
          type="email"
          placeholder="Email"
          className="input-common"
          value={email}
          onChange={handleEmailChange}
        />
        <input
          type="password"
          placeholder="Password"
          className="input-common"
          value={password}
          onChange={handlePasswordChange}
        />
        <p className="anchor" onClick={onForgotPassword}>Forgot your password?</p>
        <button type="submit" className="cmn-btn" disabled={isLoading}>
          Log In
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
